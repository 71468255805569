<template>
  <div>
    <v-form ref="from" v-model="valid" @submit.prevent="search()">
      <input type="submit" hidden />
      <v-row>
        <v-col cols="0" md="1"></v-col>
        <v-col cols="5" md="2">
          <v-text-field
            :autofocus="autofocus"
            outlined
            v-model="formData.streetNumber"
            label="Street Number"
          ></v-text-field>
        </v-col>
        <v-col cols="7" md="4">
          <v-text-field outlined v-model="formData.streetName" label="Street Name"></v-text-field>
        </v-col>
        <v-col cols="6" md="4" class="lookup-btn">
          <v-btn
            class="mt-2 text-capitalize"
            :loading="loading"
            color="primary"
            large
            dark
            @click="search()"
            >Lookup Map-Parcel <v-icon>mdi-magnify</v-icon></v-btn
          >
        </v-col>
        <v-col cols="0" md="1"></v-col>
      </v-row>
      <div class="not-found my-3">
        <peeps-alert-animation
          ref="alertError"
          color="peeps-light-yellow"
          :alertAttrs="{ dark: false, outlined: false }"
          :message="alertErrorMessage"
        ></peeps-alert-animation>
      </div>
      <div class="result">
        <div v-if="res && res.data.length > 1" class="primary--text text-h6">
          <strong>{{ res.data.length }}</strong> parcel{{ res.data.length > 1 ? 's' : '' }}
          found
          <slot name="resultSuffix"></slot>
        </div>
        <v-simple-table
          v-if="parcelList.length"
          :style="{ 'max-height': maxHeight, 'overflow-y': 'scroll' }"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Map-Parcel</th>
                <th class="">Address</th>
                <th class="">Owner</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer"
                @click="detail(parcel)"
                v-for="(parcel, index) in parcelList"
                :key="index"
              >
                <td class="text-center">{{ parcel.parcelId }}</td>
                <td class="">{{ parcel.address }}</td>
                <td class="">{{ parcel.owner }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-form>
  </div>
</template>

<script>
import parcel from '../mixins/parcel';
import config from '../config';
import PeepsAlertAnimation from '@/components/Core/Alerts/PeepsAlertAnimation.vue';
const api = config.api();
export default {
  mixins: [parcel],
  props: {
    maxHeight: {
      type: String
    },
    autofocus: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PeepsAlertAnimation
  },
  data() {
    return {
      formData: {},
      valid: true,
      error: false,
      loading: false,
      alertErrorMessage: '',
      res: null
    };
  },
  computed: {
    parcelList() {
      let res = [];
      if (this.res) {
        res = this.res.data;
      }
      res.map(e => {
        e.parcelId = this.removePadding(this.toMapParcel(e.parcelId));
      });
      return res;
    }
  },
  methods: {
    async search() {
      this.error = false;
      this.loading = true;
      let res;
      try {
        const url = `${api.truroAPI}/GetParcelByAddress?streetNo=${
          this.formData.streetNumber || ''
        }&streetName=${this.formData.streetName || ''}`;
        res = await this.$axios.get(url);
      } catch (err) {
        this.error = true;
        this.alertErrorMessage = 'Something went wrong!';
        this.$refs.alertError.show();
        console.log(err);
      }
      if (!res || !res.data) {
        return;
      }
      this.loading = false;
      res = res.data;

      if (res.data.length == 0) {
        this.alertErrorMessage = 'No parcels found';
        this.$refs.alertError.show();
      }

      if (res.data.length == 1) {
        this.detail(res.data[0]);
      }

      this.res = res;
    },
    detail(parcel) {
      this.$emit('click:parcel', parcel);
    }
  }
};
</script>

<style lang="sass">
@media (max-width:960px)
  .lookup-btn
    margin-top: -3rem

::-webkit-scrollbar
  -webkit-appearance: none
  width: 7px

::-webkit-scrollbar-thumb
  border-radius: 4px
  background-color: rgba(0, 0, 0, .5)
  box-shadow: 0 0 1px rgba(255, 255, 255, .5)
</style>
